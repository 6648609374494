var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "represent-mission", attrs: { "d-flex": "" } },
    [
      _c("div", { staticClass: "represent-mission__scrollable" }, [
        _c(
          "div",
          { ref: "grid", staticClass: "represent-mission__grid" },
          [
            _vm._l(_vm.images, function (image, i) {
              return _c("RepresentImage", {
                key: "image-" + i,
                staticClass: "represent-mission__sqaure",
                class: _vm.isSelectOn(image.url, image.state)
                  ? "click-on"
                  : null,
                style:
                  "width: " + _vm.length + "px; height: " + _vm.length + "px;",
                attrs: { state: image.state, url: image.url },
                nativeOn: {
                  click: function ($event) {
                    return _vm.onSelect(image.url, image.state)
                  },
                },
              })
            }),
            _c("ResizeObserver", { on: { notify: _vm.handleResize } }),
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }