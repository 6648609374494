// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/bow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* These colors are supposed to by dynamyc during the runtime */\n/* Please, don't place static colors here, if they are not supposed to be changed in runtime */\n/** Please, use this mixin as the SSOT **/\n/** $theme: \"light\" | \"dark\" **/\n.rtb-border-radius {\n  border-radius: 8px;\n}\n.rtb-border {\n  border: solid 0px var(--primary-accent-color);\n}\n.rtb-box-shadow {\n  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);\n}\n.represent-mission {\n  position: relative;\n  max-width: 100%;\n  max-height: 100%;\n  text-align: left;\n}\n.represent-mission__scrollable {\n  overflow-y: auto;\n  display: flex;\n  align-items: flex-start;\n  position: relative;\n}\n.represent-mission__grid {\n  position: relative;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  align-content: center;\n  min-height: 100%;\n}\n.represent-mission__sqaure {\n  display: inline-block;\n  text-align: center;\n  background-color: #fff;\n  overflow: hidden;\n}\n.represent-mission__sqaure.before {\n  background-color: var(--secondary-accent-color);\n  border: 1px solid var(--primary-accent-color);\n  background-size: cover;\n  background-position: top right;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.represent-mission__sqaure.after {\n  background-color: var(--secondary-accent-color);\n  border: 1px solid var(--primary-accent-color);\n  background-size: cover;\n  background-position: top right;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.represent-mission__sqaure.available {\n  border: 1px solid var(--primary-accent-color);\n  background-position: center;\n  background-size: cover;\n  background-repeat: no-repeat;\n  width: inherit;\n  height: inherit;\n}\n.represent-mission__sqaure.click-on {\n  border: 1px solid var(--correct-color);\n  cursor: pointer;\n}", ""]);
// Exports
module.exports = exports;
