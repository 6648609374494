<template>
  <v-flex d-flex class="represent-mission">
    <div class="represent-mission__scrollable">
      <div class="represent-mission__grid" ref="grid">
        <RepresentImage
          v-for="(image, i) in images"
          :key="`image-${i}`"
          :state="image.state"
          class="represent-mission__sqaure"
          :class="isSelectOn(image.url, image.state) ? 'click-on' : null"
          :style="`width: ${length}px; height: ${length}px;`"
          :url="image.url"
          @click.native="onSelect(image.url, image.state)"
        />
        <ResizeObserver @notify="handleResize" />
      </div>
    </div>
  </v-flex>
</template>

<script>
import { GameMixin } from "@/mixins"
import { mapGetters, mapActions } from "vuex"
import { ResizeObserver } from "vue-resize"
import Mode from "@shared/enums/Mode"
import RepresentImage from "./RepresentImage"

import fit from "@shared/helpers/fit"

const MIN_LENGTH = 64

export default {
  name: "Represent",
  mixins: [GameMixin],
  props: {
    mode: String,
    mission: Object
  },
  components: {
    ResizeObserver,
    RepresentImage
  },
  computed: {
    ...mapGetters({
      teams: "chats"
    }),
    ...mapGetters("auth", ["user", "isPlayer"]),
    ...mapGetters(["gameStatus"]),
    ...mapGetters(["missionCompleted", "missionPlaysArray", "isScribe"]),
    missionID() {
      return this.mission?.id
    },
    isPlayOn() {
      return (
        this.mode === Mode.Play ||
        this.mode === Mode.Huddle ||
        this.mode === Mode.Social
      )
    },
    media() {
      return Array.isArray(this.gameStatus.media) ? this.gameStatus.media : []
    },
    images() {
      if (!Array.isArray(this.mission?.representImages)) return []
      return this.mission.representImages.map(url => {
        let state
        if (!this.media.includes(url)) {
          state = "before"
        } else if (
          this.missionPlaysArray.some(play => play?.answer?.[0]?.image === url)
        ) {
          state = "after"
        } else {
          state = "available"
        }
        return { url, state }
      })
    },
    length() {
      if (!this.images.length) return 0
      return Math.max(
        MIN_LENGTH,
        fit(this.width, this.height, this.images.length) - 1
      )
    }
  },
  data() {
    return {
      width: 0,
      height: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.height = this.$refs.grid.offsetHeight
      this.width = this.$refs.grid.offsetWidth
    })
  },
  methods: {
    ...mapActions(["updateGameMedia"]),
    isSelectOn(url, state) {
      if (this.isHost) {
        return state === "before" && !this.media.includes(url)
      } else if (this.isPlayer) {
        return (
          this.isScribe &&
          !this.missionCompleted &&
          state === "available" &&
          this.isPlayOn &&
          this.media.includes(url)
        )
      }
    },
    handleResize({ width, height }) {
      this.height = height
      this.width = width
    },
    async onSelect(url, state) {
      if (!this.isSelectOn(url, state)) return
      if (this.isHost) {
        this.updateGameMedia({ media: [...this.media, url] })
      } else if (this.isPlayer) {
        this.imageUrl = url
        this.hasImage = true
        this.missionStatus = "completed"
        await this.checkAnswer({
          sentMissionID: this.missionID
        })
      }
    }
  }
}
</script>

<style lang="scss">
.represent-mission {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  text-align: left;
  // padding-top: 25px;
  // padding-bottom: 48px;

  &__scrollable {
    overflow-y: auto;
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  &__grid {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    min-height: 100%;
  }

  &__sqaure {
    display: inline-block;
    text-align: center;
    background-color: #fff;
    overflow: hidden;
    &.before {
      background-color: $secondary_accent_color;
      border: 1px solid $primary_accent_color;
      background-size: cover;
      background-position: top right;
      background-image: url("../../../../assets/bow.png");
    }
    &.after {
      background-color: $secondary_accent_color;
      border: 1px solid $primary_accent_color;
      background-size: cover;
      background-position: top right;
      background-image: url("../../../../assets/bow.png");
    }
    &.available {
      border: 1px solid $primary_accent_color;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      width: inherit;
      height: inherit;
    }
    &.click-on {
      border: 1px solid $correct_color;
      cursor: pointer;
    }
  }
}
</style>
