<template>
  <transition name="represent-image-transition" mode="out-in">
    <div
      key="available"
      v-if="state === 'available'"
      class="available"
      :style="`background-image: url(${url})`"
    />
    <div key="before" v-else-if="state === 'before'" class="before" />
    <div key="after" v-else class="after" />
  </transition>
</template>

<script>
export default {
  name: "RepresentImage",
  props: {
    state: String,
    url: String,
    key: Number
  }
}
</script>

<style lang="scss">
.represent-image-transition {
  &-enter-active,
  &-leave-active {
    transition: transform 0.3s ease;
    transform: rotateY(0deg);
  }
  &-enter {
    transform: rotateY(-90deg);
  }
  &-leave-to {
    transform: rotateY(90deg);
  }
}
</style>
