var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "represent-image-transition", mode: "out-in" } },
    [
      _vm.state === "available"
        ? _c("div", {
            key: "available",
            staticClass: "available",
            style: "background-image: url(" + _vm.url + ")",
          })
        : _vm.state === "before"
        ? _c("div", { key: "before", staticClass: "before" })
        : _c("div", { key: "after", staticClass: "after" }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }